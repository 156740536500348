import { PageContainer } from '../../components/layout'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useRedirectOnAuthChanges } from '@dtx-company/inter-app/src/hooks/auth/useRedirectOnAuthChanges'
import CodeNotFoundContainer from '../../containers/404/code-not-found'

const NotFound = (): JSX.Element => {
  useRedirectOnAuthChanges()

  return (
    <PageContainer
      disableNotifications
      disableSideNav
      disableTopNav
      disableFooter
      overrideMobile
      page={Routes.CODE_NOT_FOUND}
    >
      <CodeNotFoundContainer />
    </PageContainer>
  )
}

export default NotFound
