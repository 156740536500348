import * as assets from '@app/code/src/constants/assets'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CONTACT_US_EMAIL } from '@dtx-company/true-common/src/constants/contactUs'
import { HELP_URL } from '@dtx-company/true-common/src/constants/help'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import Link from 'next/link'

const CodeNotFound = (): JSX.Element => {
  return (
    <Box
      width="fit-content"
      flexDirection="column"
      justifyContent="center"
      margin="0 auto"
      height="100%"
      px={['16px', null, null]}
    >
      <Image alt="" src={assets.flowcodeGeneral} height="64px" width="64px" />

      <Spacer mt="16px" />

      <Image
        alt="flowcode"
        src={assets.flowcodeRectangleLogoBlack}
        height="32px"
        maxWidth="226px"
      />

      <Spacer mt="16px" />

      <Text variant="display/small">This code was deleted by the original creator</Text>

      <Spacer mt="16px" />

      <Box maxWidth="560px" flexDirection="column">
        <Text variant="body/medium" color="primary.grey" lineHeight="initial">
          This scan destination cannot be reached because this code was deleted by its original
          creator.
        </Text>

        <Spacer mt="16px" />

        <Text variant="body/medium" color="primary.grey" lineHeight="initial">
          To make your own Flowcode, visit our{' '}
          <Link href={Routes.INDEX}>
            <Text
              cursor="pointer"
              textDecoration="underline"
              component="span"
              variant="body/small"
              fontWeight="bold"
              color="primary.flowBlue"
            >
              Homepage
            </Text>
          </Link>
          . If you have any urgent questions or needs, you can view our{' '}
          <a href={HELP_URL} rel="noopener noreferrer" target="_blank">
            <Text
              textDecoration="underline"
              component="span"
              variant="body/small"
              fontWeight="bold"
              color="primary.flowBlue"
            >
              Help Center
            </Text>
          </a>{' '}
          or{' '}
          <a href={`mailto:${CONTACT_US_EMAIL}`}>
            <Text
              textDecoration="underline"
              component="span"
              variant="body/small"
              fontWeight="bold"
              color="primary.flowBlue"
            >
              Contact Us
            </Text>
          </a>
          .
        </Text>
      </Box>
    </Box>
  )
}

export default CodeNotFound
