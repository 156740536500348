
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/404/code-not-found",
      function () {
        return require("private-next-pages/404/code-not-found.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/404/code-not-found"])
      });
    }
  